import { GET_ICONS, successAction, GET_ICONS_BY_ID } from "../types";
import {
  getDataFromResponse,
  getDataFromSingleResponse,
  getFiltredTotalFromResponse
} from "./utils";

const defaultState = {
  data: []
};

export default function icons(state = defaultState, action) {
  switch (action.type) {
    /*case successAction(GET_ICONS): {
      return {
        ...state,
        data: getDataFromResponse(action.payload),
        total: getFiltredTotalFromResponse(action.payload)
      };
    }
    case successAction(GET_ICONS_BY_ID): {
      const newAuthor = getDataFromSingleResponse(action.payload);

      return {
        ...state,
        data: [
          ...state.data.filter(author => author["@id"] !== newAuthor["@id"]),
          newAuthor
        ]
      };
    }*/
    default:
      return state;
  }
}
