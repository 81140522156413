import React from "react";
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import {client} from "../../../prismic-configuration";

export default class Categories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categoriesIDs: this.props.categoriesID,
            categories: ""
        };
    }

    getCategories() {
        if(this.state.categoriesIDs.length) {
            client.getByIDs(this.state.categoriesIDs).then(categories => {
                if (categories) {
                    this.setState({categories: categories});
                } else {
                    console.warn('Categories not found');
                }
            });
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.categoriesID !== prevState.categoriesIDs) {
            return {
                categoriesIDs: nextProps.categoriesID
            };
        }
        else return null;
    }

    componentDidMount() {
        this.getCategories();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.categoriesIDs !== this.state.categoriesIDs) {
            this.getCategories();
        }
    }

    render() {
        const categories = [];

        if(this.state.categories) {
            this.state.categories.results.forEach(category => categories.push(
                <Link to={`/category/${category.uid}`} className="b-iconpage__category" key={category.uid}>{RichText.asText(category.data.title)}</Link>
            ));
        }

        return categories;
    }
}