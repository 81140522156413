import React from "react";
import { RichText } from 'prismic-reactjs'
import htmlSerializer from '../../../utils/htmlSerializer'

export default class DidYouKnow extends React.Component {

    render() {
        const title = this.props.render.length ? <p><b>Did you know?:</b></p> : "";
        return (
            <>
            {title}
            <RichText render={this.props.render} htmlSerializer={htmlSerializer} />
            </>
        );
    }
}