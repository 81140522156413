import React from "react";
import Main from './Main'
import Catalog from './Catalog'

export default class Index extends React.Component {
    render() {

        if(window.innerWidth > 992 ) {
            return (
                <Main {...this.props} />
            );
        } else {
            return (
                <Catalog {...this.props}/>
            );
        }

    }
}