import React from "react";

export default class Stats extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            icon: this.props.icon,
            viewed: 0,
            shared: 0,
            isLoaded: false
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.icon !== prevState.icon) {
            return {
                icon: nextProps.icon
            };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        console.log(prevState.icon !== this.state.icon);
        if (prevState.icon !== this.state.icon) {
            this.getStats();
        }
    }

    getStats() {
        if(this.state.icon.id) {
            fetch("/counter.php?page="+this.state.icon.id+"&viewed=1")
                .then(res => res.json())
                .then(
                    (result) => {
                        this.setState({
                            isLoaded: true,
                            viewed: result[this.state.icon.id].viewed,
                            shared: result[this.state.icon.id].shared
                        });
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    }
                );
        }
    }

    render() {
        return (
            <div className="b-iconpage__views">
                <span><b>Viewed:</b> {this.state.viewed}</span>
                <span><b>Shared:</b> {this.state.shared}</span>
            </div>
        );
    }
}