import React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { Provider, connect } from "react-redux"
import store from './store'
import { apiEndpoint } from './prismic-configuration'
import Index from './pages/Index'
import ContactUs from './pages/ContactUs'
import Text from './pages/Text'
import Catalog from './pages/Catalog'
import Icon from './pages/Icon'
import Search from './pages/Search'
import Preview from './pages/Preview'
import Privacy_policy from './pages/Privacy_policy'
import NotFound from './pages/NotFound'

export default class App extends React.Component {
  render() {
    const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
    const repoName = repoNameArray[1];

    return (
      <>
        <Helmet>
          <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
        </Helmet>
        <BrowserRouter>
          <Switch>
              <Route exact path='/preview' component={Preview} />
              <Route exact path='/' component={Index} />
              <Route exact path='/about' component={Text} />
              <Route exact path='/contactus' component={ContactUs} />
              <Route exact path='/privacy_policy' component={Privacy_policy} />
              <Route exact path='/category/:uid' component={Catalog} />
              <Route exact path='/icon/:uid' component={Icon} />
              <Route exact path='/search' component={Search} />
            <Route exact path='*' component={NotFound} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
