import Prismic from 'prismic-javascript'
import {Route} from "react-router-dom";
import Text from "./pages/Text";
import ContactUs from "./pages/ContactUs";
import Privacy_policy from "./pages/Privacy_policy";
import Catalog from "./pages/Catalog";

// -- Prismic API endpoint
// Determines which repository to query and fetch data from
export const apiEndpoint = 'https://iconsua.cdn.prismic.io/api/v2'

// -- Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
const accessToken = ''

// -- Link resolution rules
// Manages the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.type === 'category') return `/category/${doc.uid}`;
  if (doc.type === 'icon') return `/icon/${doc.uid}`;
  if (doc.type === 'privacy_policy') return `/privacy_policy`;
  if (doc.type === 'about_us') return `/about`;
  return '/'
}

// Client method to query documents from the Prismic repo
export const client = Prismic.client(apiEndpoint, { accessToken });

// API method to query documents from the Prismic repo
export const API = Prismic.api(apiEndpoint, { accessToken });
