import React from "react";
import Page from "../components/page/Page";
import Header from "../components/header/Header";
import MainContent from "../components/mainContent/MainContent";
import Footer from "../components/footer/Footer";
import HubspotForm from 'react-hubspot-form'

export default class Text extends React.Component {
    componentDidMount () {

    }

    render() {
        return (
            <Page>
                <Header />
                <MainContent>
                    <div className="container">
                        <div className="row row_flex">
                            <div className="col-xs-12 col-md-4 hidden-sm hidden-xs">
                                <img
                                    src={require("../assets/images/contactus.png")}
                                    alt=""
                                    className="b-page__icon" />
                            </div>
                            <div className="col-xs-12 col-md-8">
                                <h1 className="b-page__title">Contact us</h1>
                                <iframe src="https://veedoo.fibery.io/@public/forms/S6KvaBoC?hide-cover" style={{
                                    'width':'100%',
                                    'height':'1200px',
                                    'padding':0,
                                    'border':'none',
                                }}/>
                            </div>
                        </div>
                    </div>
                </MainContent>
                <Footer />
            </Page>
        );
    }
}