import React from "react";
import { RichText } from 'prismic-reactjs'
import {client} from "../../../prismic-configuration";

export default class Tags extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tags: this.props.tags
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.tags !== prevState.tags) {
            return {
                tags: nextProps.tags
            };
        }
        else return null;
    }

    render() {
        const tags = [];

        if(this.state.tags) {
            this.state.tags.forEach((tag, index) => tags.push(
                <li key={index}>{tag}</li>
            ));
        }

        return (
            <ul className="b-iconpage__tags">
                {tags}
            </ul>
        );
    }
}