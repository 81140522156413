import React from "react";
import './assets/scss/b-main.scss';

export default class MainContent extends React.Component {
    render() {
        return (
            <div className="b-main">
                {this.props.children}
            </div>
        );
    }
}