import React from "react";
import { Link } from 'react-router-dom'
import { RichText } from 'prismic-reactjs'
import './assets/scss/b-icon.scss'

export default class IconThumbnail extends React.Component {
    render() {
        return (
            <Link className="b-icon b-icon_catalog" to={`/icon/${this.props.item.uid}`}>
                <span className="b-icon__wrap">
                    <img
                        className="b-icon__image"
                        src={this.props.item.data.iconimage.url}
                        alt={RichText.asText(this.props.item.data.iconname)}/>
                </span>
                <span className="b-icon__title">{RichText.asText(this.props.item.data.iconname)}</span>
                <span className="b-icon__overlay" />
            </Link>
        );
    }
}