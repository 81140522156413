import React from "react";
import { API } from '../../prismic-configuration';

import './assets/scss/b-page.scss';

export default class Page extends React.Component {

    /*componentDidMount() {
        API.then(function(API) {
            return API.query("");
        }).then(function(response) {
            console.log(response);
            return response;
        });
    }*/

    render() {
        return (
            <div className="b-page">
                {this.props.children}
            </div>
        );
    }
}