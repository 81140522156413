import React from "react";
import Page from "../components/page/Page";
import Header from "../components/header/Header";
import MainContent from "../components/mainContent/MainContent";
import Footer from "../components/footer/Footer";
import {client} from "../prismic-configuration";
import Prismic from "prismic-javascript";
import {RichText} from "prismic-reactjs";
import htmlSerializer from "../utils/htmlSerializer";

export default class Privacy_policy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            page: null
        };
    }

    componentDidMount() {
        client.query([
            Prismic.Predicates.at('document.type', 'privacy_policy')
        ], {
            pageSize : 100,
            page : 1
        }).then(response => {

            response.results.forEach((value, index) => {
                if(index === 0) {
                    this.setState({
                        page: value
                    });
                }
            });

        });
    }

    render() {
        const title = this.state.page ? RichText.asText(this.state.page.data.title) : "";
        const text = this.state.page ? <RichText render={this.state.page.data.policy} htmlSerializer={htmlSerializer} /> : "";

        return (
            <Page>
                <Header />
                <MainContent>
                    <div className="container">
                        <h1 className="b-page__title">{title}</h1>
                        <div className="b-text">
                            {text}
                        </div>
                    </div>
                </MainContent>
                <Footer />
            </Page>
        );
    }
}