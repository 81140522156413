import {Link} from "react-router-dom";
import React from "react";
import Page from "../components/page/Page";
import Header from "../components/header/Header";
import MainContent from "../components/mainContent/MainContent";
import Footer from "../components/footer/Footer";

export default class NotFound extends React.Component {
    render() {
        return (
            <Page>
                <Header />
                <MainContent>
                    <div className="container">
                        <h1 className="b-page__title">404</h1>
                        <div className="b-text">
                            <h2>Document not found</h2>
                            <p><Link to="/">Return to homepage</Link></p>
                        </div>
                    </div>
                </MainContent>
                <Footer />
            </Page>
        );
    }
}