import React from "react";
import './assets/scss/b-footer.scss'
import {client} from "../../prismic-configuration";
import { RichText } from 'prismic-reactjs'
import Prismic from "prismic-javascript";

export default class Footer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            copyright: null,
            facebook: null
        };
    }

    componentDidMount() {
        client.query([
            Prismic.Predicates.at('document.type', 'site_configuration'),
        ]).then(response => {
            response.results.forEach(item => {
                this.setState({
                    copyright: RichText.asText(item.data.copyrights),
                    facebook: RichText.asText(item.data.facebook_link)
                });
            });
        });
    }


    render() {
        const facebook = this.state.facebook !== null ? <a href={this.state.facebook} title="Facebook" rel="noopener noreferrer" className="facebook" target="_blank"></a> : "";

        return (
            <footer className="b-footer">
                <a href="https://veedoo.io/" className="b-footer__created" target="_blank" rel="noopener noreferrer">
                    <img
                        src={require("../../assets/images/vedoo_logo.svg").default}
                        alt="Veedoo Logo"
                        className="b-footer__logo" />
                        <div className="b-footer__copyrights">{this.state.copyright}</div>
                </a>
                <div className="b-footer__social">
                    {facebook}
                </div>
            </footer>
        );
    }
}