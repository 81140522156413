import React from "react";
import { Link } from 'react-router-dom'

import './assets/scss/b-header.scss'
import Menu from "../headerMenu/HeaderMenu";

export default class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchQuery: ""
        };

        this.searchRef = React.createRef();

        this.onChangeSearchQuery = this.onChangeSearchQuery.bind(this);
        this.onSubmitSearchQuery = this.onSubmitSearchQuery.bind(this);
        this.onClickSearchSubmit = this.onClickSearchSubmit.bind(this);
    }
    onChangeSearchQuery(e) {
        const queryValue = e.target.value;

        this.setState({
            searchQuery: queryValue
        });
    }

    onSubmitSearchQuery(e) {
        if(this.state.searchQuery.length <= 3) {
            e.preventDefault();
        }
    }

    onClickSearchSubmit(e) {
        if(this.state.searchQuery.length <= 3) {
            this.searchRef.current.classList.toggle('opened');
            e.preventDefault();
        }
    }

    render() {
        const typeClass = this.props.type === "transparent" ? "b-header_transparent" : "";
        const logo = this.props.type === "transparent" ? require("../../assets/images/transparent_logo.svg").default : require("../../assets/images/logo.svg").default;

        return (
            <header className={`b-header ${typeClass}`}>
                <div className="b-header__left">
                    <Link className="b-header__logo" to="/">
                        <img
                        src={logo}
                        alt="Ukrainian icons"
                        />
                    </Link>
                </div>
                <div className="b-header__right">
                    <div className="b-header__search" ref={this.searchRef}>
                        <form action="/search" method="get" onSubmit={this.onSubmitSearchQuery}>
                            <div className="b-header__search-input-wrap">
                                <input type="text" className="b-header__search-input" name="q" onChange={this.onChangeSearchQuery} value={this.state.searchQuery} />
                            </div>
                            <button className="b-header__search-submit" onClick={this.onClickSearchSubmit}/>
                        </form>
                    </div>
                    <Menu />
                </div>
            </header>
        );
    }
}