import qs from 'query-string'

export function setQueryStringWithoutPageReload(qsValue) {
    const newurl = window.location.protocol + "//" +
        window.location.host +
        window.location.pathname +
        qsValue;

    window.history.pushState({ path: newurl }, "", newurl);
}

export function set(key, value, queryString = window.location.search) {
    const values = qs.parse(queryString);
    const newQsValue = qs.stringify({ ...values, [key]: value });
    setQueryStringWithoutPageReload(`?${newQsValue}`);
}

export function get(key, queryString = window.location.search) {
    const values = qs.parse(queryString);
    return values[key];
}