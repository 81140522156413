import React from "react";
import Prismic from 'prismic-javascript'
import { client } from '../prismic-configuration'
import {set as queryStringUtilsSet, get as queryStringUtilsGet} from '../utils/queryStringUtils'

import Header from "../components/header/Header";
import Page from "../components/page/Page";
import MainContent from "../components/mainContent/MainContent";
import IconThumbnail from "../components/icon_thumbnail/IconThumbnail";
import Footer from "../components/footer/Footer";

import "../assets/scss/b-category.scss";

export default class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            query: null,
            title: null,
            icons: null,
            searchQuery: "",
            nothingFound: false
        };

        this.onChangeSearchQuery = this.onChangeSearchQuery.bind(this);
    }

    _getIcons(query) {
        client.query([
            Prismic.Predicates.at('document.type', 'icon'),
            Prismic.Predicates.fulltext('document', query)
        ], { pageSize : 100 }).then(icons => {
            if(icons && icons.results_size > 0) {
                this.setState({
                    icons: icons,
                    nothingFound: false
                });
            } else{
                this.setState({
                    icons: null,
                    nothingFound: true
                });
            }
        });
    }

    componentDidMount() {
        const queryValue = queryStringUtilsGet('q') ? queryStringUtilsGet('q') : "";

        if(queryValue.length > 3) {
            this.setState({
                searchQuery: queryValue
            });
            this._getIcons(queryValue);
        } else {
            this.setState({
                icons: null,
                nothingFound: true,
                searchQuery: queryValue
            });

        }
    }

    onChangeSearchQuery(event) {
        const queryValue = event.target.value;

        this.setState({
            searchQuery: queryValue
        });

        if(this.timeout) {
            clearTimeout(this.timeout);
        }

        if(queryValue.length > 3) {
            this.timeout = setTimeout(() => {
                this._getIcons(queryValue);
            }, 2000);
        } else {
            this.setState({
                icons: null,
                nothingFound: true
            });
        }
        queryStringUtilsSet('q', queryValue);
    }

    render() {
        const icons = [];

        if(this.state.icons) {
            this.state.icons.results.forEach(icon => icons.push(
                <IconThumbnail item={icon} key={icon.id}/>
            ));
        } else if(this.state.nothingFound) {
            icons.push(<div className="b-category__empty-text" key="icons_not_found">Sorry, but nothing found. Try to use another search query.</div>);
        }

        return (
            <Page>
                <Header />
                <MainContent>
                    <div className="container">
                        <div className="b-category b-category_search">
                            <div className="b-category__header">
                                <form action="/search" method="get">
                                    <input className="b-category__search-field"
                                           type="text"
                                           name="q"
                                           placeholder=""
                                           value={this.state.searchQuery}
                                           onChange={this.onChangeSearchQuery} />
                                </form>
                            </div>
                            <div className="b-category__icons">{icons}</div>
                        </div>
                    </div>
                </MainContent>
                <Footer />
            </Page>
        );
    }
}