import React from "react";
import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import { client } from '../prismic-configuration'
/*import NotFound from './NotFound'*/

import Header from "../components/header/Header";
import Page from "../components/page/Page";
import MainContent from "../components/mainContent/MainContent";
import IconThumbnail from "../components/icon_thumbnail/IconThumbnail";
import Footer from "../components/footer/Footer";

import "../assets/scss/b-category.scss";

export default class Category extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categoryUID: this.props.match.params.uid,
            category: null,
            notFound: false,
            toggleNotFound: false,
            title: null,
            icons: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.uid !== prevState.iconUID) {
            return {
                categoryUID : nextProps.match.params.uid
            };
        }
        else return null;
    }

    getCategory() {

        if(typeof this.state.categoryUID === "undefined" || this.state.categoryUID === "all") {
            client.query([
                Prismic.Predicates.at('document.type', 'icon')
            ], { pageSize : 100 }).then(icons => {
                if(icons) {
                    this.setState({icons: icons});
                } else{
                    console.warn('Category is empty');
                }
            });
        } else{
            client.getByUID('category', this.state.categoryUID).then(category => {
                if (category) {
                    this.setState({category: category});
                    client.query([
                        Prismic.Predicates.at('document.type', 'icon'),
                        Prismic.Predicates.at('my.icon.iconcategory.category', category.id)
                    ], { pageSize : 100 }).then(icons => {
                        if(icons) {
                            this.setState({icons: icons});
                        } else{
                            console.warn('Category is empty');
                        }
                    });
                } else {
                    console.warn('Category not found');
                    //toggleNotFound(true)
                }
            });
        }
    }

    componentDidMount() {
        this.getCategory();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.categoryUID !== this.state.categoryUID) {
            this.getCategory();
        }
    }

    render() {
        const categoryTitle = this.state.category ? RichText.asText(this.state.category.data.title) : typeof this.props.match.params.uid === "undefined" || this.props.match.params.uid === "all" ? "All icons" : "";

        const icons = [];

        if(this.state.icons) {
            this.state.icons.results.forEach(icon => icons.push(
                <IconThumbnail item={icon} key={icon.id}/>
            ));
        }

        return (
            <Page>
                <Header />
                <MainContent>
                    <div className="container">
                        <div className="b-category">
                            <div className="b-category__header">
                                <div className="b-category__name">{categoryTitle}</div>
                            </div>
                            <div className="b-category__icons">{icons}</div>
                        </div>
                    </div>
                </MainContent>
                <Footer />
            </Page>
        );
    }
}