import React from "react";
import IconThumbnail from "../../icon_thumbnail/IconThumbnail";
import {client} from "../../../prismic-configuration";

export default class RelatedIcons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            iconsID: this.props.iconsID,
            icons: null
        };
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.iconsID !== prevState.iconsID) {
            return {
                iconsID: nextProps.iconsID
            };
        }
        else return null;
    }

    getIcons() {
        if(this.state.iconsID.length) {
            client.getByIDs(this.state.iconsID).then(icons => {
                if (icons) {
                    this.setState({icons: icons});
                } else {
                    console.warn('Icons not found');
                }
            });
        }
    }

    componentDidMount() {
        this.getIcons();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.iconsID !== this.state.iconsID) {
            this.getIcons();
        }
    }

    render() {
        const icons = [];

        if(this.state.icons) {
            this.state.icons.results.forEach(icon => icons.push(
                <IconThumbnail item={icon} key={icon.id}/>
            ));
        }

        return (
            <div className="b-iconpage__related">
                <div className="b-iconpage__related-title">Related icons</div>
                <div className="b-iconpage__related-icons">
                    {icons}
                </div>
            </div>
        );
    }
}